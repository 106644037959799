import React, { useState, useEffect } from "react"
import "table.css"
import useDashboardAPI from "rest/useDashboardAPI"
import { floor } from "libs/math"

function UnstakeStatus() {
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50) // Number of items to display per page

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const timestamp = Date.now() // Get the current timestamp and use for cache-Busting
      const url = `https://raw.githubusercontent.com/lbunproject/BASEswap-api-price/main/public/unstaked_plus_hashes.json?t=${timestamp}`
      const response = await fetch(url)
      const data = await response.json()
      setTableData(data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  // Function to generate table rows for the current page
  const renderTableRows = () => {
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem)

    return currentItems.map((row, index) => {
      
    const unstakeHashLink = `<a href="https://finder.terraclassic.community/mainnet/tx/${
        row.txHashUnstake}">${row.txHashUnstake.slice(0, 4)}...${row.txHashUnstake.slice(-4)}</a>`
      
    // Check if txHashToUser is empty
    const releaseHashLink = row.txHashToUser
      ? `<a href="https://finder.terraclassic.community/mainnet/tx/${
          row.txHashToUser
        }">${row.txHashToUser.slice(0, 4)}...${row.txHashToUser.slice(-4)}</a>`
      : ""; // Display releaseDate if txHashToUser is empty

    // Calculate the difference between releaseDate and unstakeDate in days
    const daysDifference =
      row.txHashToUser === ""
        ? calculateDaysDifference(new Date(), new Date(row.releaseDate))
        : "";

      return (
        <tr key={index}>
          <td>{row.unstakeDate}</td>
          <td>{row.releaseDate}</td>
          <td>{addCommasToNumber(row.baseBurned.toFixed(6))}</td>
          <td>{addCommasToNumber(row.luncNetReleased.toFixed(6))}</td>
          <td>{row.rate.toFixed(3)}</td>
          <td>{row.sendTo.slice(0, 7) + "..." + row.sendTo.slice(-4)}</td>
          {/*<td dangerouslySetInnerHTML={{ __html: unstakeHashLink }} />*/}
          {row.txHashToUser === "" ? (
          <td>{floor(daysDifference)} days left</td>
        ) : (
          <td dangerouslySetInnerHTML={{ __html: releaseHashLink }} />
        )}
      </tr>
      )
    })
  }

// Function to calculate the difference between two dates in days
const calculateDaysDifference = (date1, date2) => {
  const differenceInTime = date2.getTime() - date1.getTime();
  const differenceInDays = (differenceInTime / (1000 * 3600 * 24)) +1;
  return differenceInDays;
};


function addCommasToNumber(number) {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // Function to handle items per page change
  const handleItemsPerPageChange = (e) => {
    const value = parseInt(e.target.value)
    setItemsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing items per page
  }

  // Calculate the total number of pages
  const totalPages = Math.ceil(tableData.length / itemsPerPage)

  // Generate page number buttons
  const renderPageNumbers = () => {
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      )
    }
    return pageNumbers
  }

  // Generate items per page options
  const renderItemsPerPageOptions = () => {
    const options = [25, 50, 100]
    return options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))
  }

  return (
    <div className="table-container">
      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr>
              <th>Unstake Date</th>
              <th>Release Date</th>
              <th>BASE Burned</th>
              <th>LUNC Released</th>
              <th>Rate</th>
              <th>Send to</th>
              {/*<th>Unstake Hash</th>*/}
              <th>Release/Hash</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="items-per-page">
          <label>Items per page:</label>
          <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
            {renderItemsPerPageOptions()}
          </select>
        </div>
        <div className="page-numbers">{renderPageNumbers()}</div>
      </div>
    </div>
  )
}

export default UnstakeStatus
