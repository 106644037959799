import React, { useState, useEffect } from "react"
import "table.css"

function TerraClassicEscrows() {
  const [escrowIds, setEscrowIds] = useState([]);
  const [escrowDetails, setEscrowDetails] = useState([]);
  let [luna1Price, setLuna1Price] = useState(0);
  let [currentPrice, setCurrentPrice] = useState(0);

  useEffect(() => {
    // Fetch data from the first URL to get escrow IDs
    fetch("https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1yppgsd5j9s5qqls22vd0td7ahs6r5caqq08aetfl82yge2s3nxps5hmxsp/smart/eyJsaXN0Ijp7fX0=")
      .then((response) => response.json())
      .then((data) => {
        setEscrowIds(data.data.escrows);
      })
      .catch((error) => {
        console.error("Error fetching escrow IDs:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch escrow details for each ID and store them in escrowDetails array
    const fetchEscrowDetails = async () => {
      const detailsPromises = escrowIds.map(async (id) => {
        const encodedId = btoa(JSON.stringify({ details: { id } }));
        const url = `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1yppgsd5j9s5qqls22vd0td7ahs6r5caqq08aetfl82yge2s3nxps5hmxsp/smart/${encodedId}`;
        
        try {
          const response = await fetch(url);
          const data = await response.json();
          return data.data;
        } catch (error) {
          console.error(`Error fetching escrow details for ID ${id}:`, error);
          return null;
        }
      });

      const details = await Promise.all(detailsPromises);
      setEscrowDetails(details);
    };

    if (escrowIds.length > 0) {
      fetchEscrowDetails();
    }
  }, [escrowIds]);

  function unixTimestampToDDMMMYY(unixTimestamp) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
    const dd = String(date.getDate()).padStart(2, '0'); // Day
    const mmm = months[date.getMonth()]; // Three-letter month abbreviation
    const yy = String(date.getFullYear()).slice(-2); // Last two digits of the year
  
    return `${dd} ${mmm} ${yy}`;
  }

  function addCommasToNumber(number) {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  useEffect(() => {
    const url =
    "https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m/smart/eyJjdXJ2ZV9pbmZvIjp7fX0="
  fetch(url)
    .then((response) => response.text())
    .then((text) => {
      //console.log(text)
      const tbcData = JSON.parse(text)
      setCurrentPrice(Number(tbcData.data.spot_price) / 1000000)
    })
    
    const url2 =
      "https://api.coingecko.com/api/v3/simple/price?ids=terra-luna&vs_currencies=usd"
    fetch(url2)
      .then((response) => response.text())
      .then((text) => {
        const apiCoinGeckoLuna = JSON.parse(text)
        setLuna1Price(Number(apiCoinGeckoLuna["terra-luna"]["usd"]))
      })
    return
  },)

  // Function to calculate remaining days
  const calculateRemainingDays = (endDate) => {
    const now = new Date();
    const end = new Date(endDate * 1000); // Convert to milliseconds
    const timeDiff = end - now;
    const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysRemaining;
  };

  // Function to generate base64-encoded ID for URL
  const generateEncodedId = (id) => {
    const idObject = { details: { id } };
    const encodedId = btoa(JSON.stringify(idObject));
    return encodedId;
  };


  // Render the table using escrowDetails data
  return (
    <div>
      <h1 className="padded-header" style={{ textAlign: 'center', fontSize: '28px' }}>
       Delegator Airdrop
     </h1>
      <div className="table-container">
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Recipient</th>
                <th>Start Date</th>
                <th>Release Date</th>
                <th>Amount (BASE)</th>
                <th>Value in LUNC</th>
                <th>Days Left</th> {/* New column */}
               </tr>
            </thead>
            <tbody>
              {escrowDetails.map((details, index) => (
                <tr key={index}>
                  <td>
                    <a
                      href={`https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1yppgsd5j9s5qqls22vd0td7ahs6r5caqq08aetfl82yge2s3nxps5hmxsp/smart/${generateEncodedId(details.id)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="id-link" // Add this class
                    >
                      {details.id}
                    </a>
                  </td>
                  <td>{details.recipient.slice(0, 7) + "..." + details.recipient.slice(-4)}</td>
                  <td>{unixTimestampToDDMMMYY(details.start_time)}</td>
                  <td>{unixTimestampToDDMMMYY(details.end_time)}</td>
                  <td>{addCommasToNumber((details.mature_balance/1000000).toFixed(6))}</td>
                  <td>{addCommasToNumber(((details.mature_balance/1000000) * currentPrice).toFixed(6))}</td>
                  <td>{calculateRemainingDays(details.end_time)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TerraClassicEscrows;
