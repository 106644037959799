import React, { useState, useEffect } from "react"
import "table.css"
import useDashboardAPI from "rest/useDashboardAPI"

function TransactionHistory() {
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(100) // Number of items to display per page
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchDataMultipleTimes()
  }, [])

  const fetchDataMultipleTimes = async () => {
    setIsLoading(true) // Set loading to true while fetching data
    try {
      const baseUrl1 =
        "https://terra-classic-fcd.publicnode.com/v1/txs?limit=100&account=terra1yz3hv2k3a5z3tuu7fufcf7xvn2aawcu7c2fzdm"
      const baseUrl2 =
        "https://terra-classic-fcd.publicnode.com/v1/txs?limit=100&account=terra1r2u8em5304u25xy0xw5ds7f40rcjqdd3e6pk06"
      const tableData1 = []
      const tableData2 = []
      let data1 = null
      let data2 = null
      let offset1 = null
      let offset2 = null

      // Function to check if a transaction has the required properties
      const isValidTransaction = (tx) => {
        // Check if the transaction has the required structure and values
        return (
          tx.tx &&
          tx.tx.value &&
          tx.tx.value.msg &&
          tx.tx.value.msg.some(
            (msg) =>
              msg.type === "wasm/MsgExecuteContract" &&
              msg.value &&
              msg.value.msg &&
              msg.value.msg.buy
          )
        )
      }
      /*
      // Fetch pages from baseUrl1 until we have enough transactions
      while (tableData1.length < 50) {
        const pageUrl1 = offset1 ? `${baseUrl1}&offset=${offset1}` : baseUrl1;
        const response1 = await fetch(pageUrl1);
        data1 = await response1.json();
  
        if (data1.txs) {
          // Filter valid transactions and add them to tableData1
          tableData1.push(...data1.txs.filter(isValidTransaction));
          offset1 = data1.next;
        }
  
        if (!data1.next) break; // Stop if no more pages are available
      }
      */

      // Fetch pages from baseUrl2 until we have enough transactions
      while (tableData2.length < 50) {
        const pageUrl2 = offset2 ? `${baseUrl2}&offset=${offset2}` : baseUrl2
        const response2 = await fetch(pageUrl2)
        data2 = await response2.json()

        if (data2.txs) {
          // Filter valid transactions and add them to tableData2
          tableData2.push(...data2.txs.filter(isValidTransaction))
          offset2 = data2.next
        }

        if (!data2.next) break // Stop if no more pages are available
      }

      // Combine tableData1 and tableData2 to make up at least 100 transactions
      const combinedTableData = [...tableData1, ...tableData2].slice(0, 100)

      // Set the state with the combined results
      setTableData(combinedTableData)
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setIsLoading(false) // Set loading to false after data is fetched
    }
  }

  // Function to generate table rows for the current page
  const renderTableRows = () => {
    // Sort the tableData from newest to oldest based on the date
    const sortedTableData = tableData.slice().sort((a, b) => {
      const dateA = new Date(a.timestamp).getTime()
      const dateB = new Date(b.timestamp).getTime()
      return dateB - dateA // Sort in descending order (newest to oldest)
    })

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = sortedTableData.slice(
      indexOfFirstItem,
      indexOfLastItem
    )

    return currentItems.map((row, index) => {
      const HashLink = `<a href="https://finder.terraclassic.community/mainnet/tx/${
        row.txhash
      }">${row.txhash.slice(0, 4)}...${row.txhash.slice(-4)}</a>`
      const date = row.timestamp.split("T")[0]
      let wasmInfo = processLog(row.logs[0])
      //fix
      if (
        row.txhash ===
        "59DD6DA92B47A3A0FF0B37E528C9D34EB33AB86EB502AB758156FCE065937C38"
      ) {
        wasmInfo["BASE Minted"] = "1444043833"
        //https://finder.terraclassic.community/mainnet/tx/F78B608A1DE7CCEF4CBEB8ACDC0B43B13FF9E832724B9550C59A1CBB19E89617
      }
      if (wasmInfo) {
        //const wasmInfo = processLog(row.logs[0]);
        const fromWallet = wasmInfo?.["from"] ?? "N/A"
        const luncSent = wasmInfo?.["LUNC Sent"] ?? "N/A"
        const luncTax = wasmInfo?.["LUNC Tax"] ?? "N/A"
        const luncStaked = wasmInfo?.["LUNC Staked"] ?? "N/A"
        const baseMinted = wasmInfo?.["BASE Minted"] ?? "N/A"
        const affiliateReward = wasmInfo?.["Affiliate Reward"] ?? ""
        let affiliateAddress = ""
        if (affiliateReward != "") {
          affiliateAddress = row.tx.value.msg[0].value.msg.buy.affiliate
        }
        const rate = luncSent / baseMinted

        return (
          // Assuming this is inside a parent element (e.g., a <tbody> or a <table>)
          <>
            {Number(luncTax) > 0 && ( // Use parentheses to group the expression
              <tr key={index}>
                <td>{date}</td>
                <td>{fromWallet.slice(0, 7) + "..." + fromWallet.slice(-4)}</td>
                <td>
                  {addCommasToNumber((Number(luncSent) / 1000000).toFixed(6))}
                </td>
                {/*<td>{addCommasToNumber((Number(luncTax) / 1000000).toFixed(6))}</td>*/}
                {/*<td>{addCommasToNumber((Number(luncStaked) / 1000000).toFixed(6))}</td>*/}
                <td>
                  {addCommasToNumber((Number(baseMinted) / 1000000).toFixed(6))}
                </td>
                <td>{addCommasToNumber(Number(rate).toFixed(3))}</td>
                <td>
                  {affiliateAddress &&
                    affiliateAddress.slice(0, 7) +
                      "..." +
                      affiliateAddress.slice(-4)}
                </td>
                {/*<td>{affiliateReward && affiliateReward !== 0 && addCommasToNumber((Number(affiliateReward) / 1000000).toFixed(6))}</td>*/}
                <td dangerouslySetInnerHTML={{ __html: HashLink }} />
              </tr>
            )}
          </>
        )
      } else {
        //console.log("No wasm information found.");
      }
    })
  }

  function addCommasToNumber(number) {
    const parts = number.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return parts.join(".")
  }

  function getAttributeValue(attributes, key) {
    const attribute = attributes.find((attr) => attr.key === key)
    return attribute ? attribute.value : null
  }

  function processLog(log) {
    if (log && log.events) {
      const wasmEvent = log.events.find((event) => event.type === "wasm")
      if (wasmEvent) {
        const attributes = wasmEvent.attributes
        const from = getAttributeValue(attributes, "from")
        const luncSent = getAttributeValue(attributes, "LUNC Sent:")
        const luncTax = getAttributeValue(attributes, "LUNC Tax:")
        const luncStaked = getAttributeValue(attributes, "LUNC Staked:")
        const baseMinted = getAttributeValue(attributes, "BASE Minted:")
        const affiliateReward = getAttributeValue(
          attributes,
          "Affiliate Reward:"
        )

        return {
          from,
          "LUNC Sent": luncSent,
          "LUNC Tax": luncTax,
          "LUNC Staked": luncStaked,
          "BASE Minted": baseMinted,
          "Affiliate Reward": affiliateReward,
        }
      }
    }
    return null
  }

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // Function to handle items per page change
  const handleItemsPerPageChange = (e) => {
    const value = parseInt(e.target.value)
    setItemsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing items per page
  }

  // Calculate the total number of pages
  const totalPages = Math.ceil(tableData.length / itemsPerPage)

  // Generate page number buttons
  const renderPageNumbers = () => {
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      )
    }
    return pageNumbers
  }

  // Generate items per page options
  const renderItemsPerPageOptions = () => {
    const options = [25, 50, 100]
    return options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))
  }

  return (
    <div className="table-container">
      <div className="table-wrapper">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              height: "100vh",
            }}
          >
            <p style={{ fontSize: "3em", fontWeight: "bold" }}>Loading...</p>
          </div>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Buyer</th>
                <th>LUNC Sent</th>
                {/*<th>LUNC Tax</th>*/}
                {/*<th>LUNC Staked</th>*/}
                <th>BASE Minted</th>
                <th>Rate</th>
                <th>Affiliate</th>
                {/*<th>Reward</th>*/}
                <th>Hash</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        )}
      </div>
      <div className="pagination">
        <div className="items-per-page">
          <label>Items per page:</label>
          <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
            {renderItemsPerPageOptions()}
          </select>
        </div>
        <div className="page-numbers">{renderPageNumbers()}</div>
      </div>
    </div>
  )
}

export default TransactionHistory
