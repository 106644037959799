import React, { memo, useEffect, useState } from "react"
import SwapPage2 from "../components/SwapPage2"
import SwapForm2 from "../forms/SwapForm2"
import Container from "../components/Container"
import { useSearchParams } from "react-router-dom"
import useMigration from "hooks/useMigration"

export enum Type {
  "SWAP" = "swap",
  "PROVIDE" = "provide",
  "WITHDRAW" = "withdraw",
}

const Vest = () => {
  const { isMigrationPage } = useMigration()
  const [searchParams, setSearchParams] = useSearchParams()

  const type = searchParams.get("type") as Type
  const tabs = {
    tabs: isMigrationPage
      ? [
        {
          name: "migration",
          title: "Migration",
        },
      ]
      : [
        { name: Type.SWAP, title: "Vest (1M LUNC minimum)" },
      ],
    selectedTabName: isMigrationPage ? "migration" : type,
  }

  let [month_apr, setMonth_Apr] = useState(0)
  let [year_apy, setYear_Apy] = useState(0)
  let [biWeek_apr, setBiWeek_Apr] = useState(0)
  let [closePrice_30day, setClosePrice_30day] = useState(0)
  let [closePrice_15day, setClosePrice_15day] = useState(0)

  const handleCurrentPriceChange = (currentPrice: number) => {
    // Do something with the currentPrice in the parent component
    //console.log("Updated currentPrice in parent:", currentPrice)
    return
  }

  const handleClosePriceChange_30day = (closePrice_30day: number) => {
    // Do something with the closePrice in the parent component
    //console.log("Updated closePrice in parent:", closePrice_30day)
    return
  }

  const handleClosePriceChange_15day = (closePrice_15day: number) => {
    // Do something with the closePrice in the parent component
    console.log("Updated closePrice in parent:", closePrice_15day)
    return
  }

  useEffect(() => {
    if (!isMigrationPage) {
      if (
        !type ||
        !Object.keys(Type)
          .map((key) => Type[key as keyof typeof Type])
          .includes(type)
      ) {
        searchParams.set("type", Type.SWAP)
        setSearchParams(searchParams, { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isMigrationPage])

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "px",
          fontSize: "28px",
        }}
      >
        <h1>BASE : 1 Year Vest</h1>

        <div style={{ color: "#CD6141", fontSize: "18px" }} />


        <h2 style={{ color: "#CD6141", fontSize: "16px" }}>
           Monthly releases from month 6 to 12
        </h2>

        <h2 style={{ color: "#FFFFFF", fontSize: "16px" }}>
           1 Million LUNC minimum
        </h2>


      </div>

      <br></br>

      <SwapPage2
        onCurrentPriceChange={handleCurrentPriceChange}
        onClosePriceChange_30day={handleClosePriceChange_30day}
        onClosePriceChange_15day={handleClosePriceChange_15day}
      >
        <>{type && <SwapForm2 type={type} tabs={tabs} />}</>
      </SwapPage2>
    </Container>
  )
}

export default memo(Vest)
