import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ContainerWrapper from '../../components/Container';
import styles from '../../components/SwapPage.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f5f5f5; /* Off-white background */
  color: #0b0b3b; /* Text color similar to swap page */
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const InputGroup = styled.div`
  margin: 10px 0;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #1c1c3b;
  color: white;
  font-size: 1em;
  text-align: right;
`;

const Result = styled.div`
  margin-top: 20px;
  font-size: 1.2em;
`;

const Highlight = styled.div`
  font-size: 2.5em;
  color: #cd6141; /* Emphasize color */
`;

const Title = styled.h1`
  font-size: 1.5em;
  color: #0b0b3b;
`;

const SubTitle = styled.h2`
  font-size: 1.2em;
  color: #cd6141; /* Emphasize color */
`;

const MemeCalculator = () => {
  const [price, setPrice] = useState(0.009654894);
  const [amount, setAmount] = useState(2000000);

  const [debouncedPrice, setDebouncedPrice] = useState(price);
  const [debouncedAmount, setDebouncedAmount] = useState(amount);

  const priceTimeoutRef = useRef(null);
  const amountTimeoutRef = useRef(null);

  const constant = 1 * 10 ** -15;

  const [startSupply, setStartSupply] = useState(0);
  const [startReserve, setStartReserve] = useState(0);
  const [endSupply, setEndSupply] = useState(0);
  const [endReserve, setEndReserve] = useState(0);
  const [endPrice, setEndPrice] = useState(0);
  const [luncRequired, setLuncRequired] = useState(0);
  const [yourGain, setYourGain] = useState(0);
  const [pumpGain, setPumpGain] = useState(0);

  useEffect(() => {
    const calculateDerivedValues = () => {
      const calculatedStartSupply = (debouncedPrice / constant) ** (1 / 2);
      const calculatedStartReserve = (constant * calculatedStartSupply ** 3) / 3;
      const calculatedEndSupply = calculatedStartSupply + Number(debouncedAmount);
      const calculatedEndReserve = (constant * calculatedEndSupply ** 3) / 3;
      const calculatedEndPrice = constant * calculatedEndSupply ** 2;
      const calculatedLuncRequired = calculatedEndReserve - calculatedStartReserve;
      const calculatedYourGain = ((calculatedEndPrice / Number(debouncedPrice)) - 1)*100;
      const calculatedPumpGain = (calculatedEndPrice / 0.009654894) - 1;

      setStartSupply(calculatedStartSupply);
      setStartReserve(calculatedStartReserve);
      setEndSupply(calculatedEndSupply);
      setEndReserve(calculatedEndReserve);
      setEndPrice(calculatedEndPrice);
      setLuncRequired(calculatedLuncRequired);
      setYourGain(calculatedYourGain);
      setPumpGain(calculatedPumpGain);
    };

    calculateDerivedValues();
  }, [debouncedPrice, debouncedAmount]);

  useEffect(() => {
    if (priceTimeoutRef.current) {
      clearTimeout(priceTimeoutRef.current);
    }
    priceTimeoutRef.current = setTimeout(() => {
      setDebouncedPrice(price);
    }, 300);
  }, [price]);

  useEffect(() => {
    if (amountTimeoutRef.current) {
      clearTimeout(amountTimeoutRef.current);
    }
    amountTimeoutRef.current = setTimeout(() => {
      setDebouncedAmount(amount);
    }, 300);
  }, [amount]);

  function addCommasToNumber(number) {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const formatInputValue = (value) => {
    // Remove any characters that are not numbers or a single decimal point at the beginning
    let newValue = value.replace(/[^0-9.]/g, "");
  
    // Handle cases with multiple periods (.)
    const parts = newValue.split(".");
    if (parts.length > 2) {
      newValue = parts[0] + "." + parts.slice(1).join("");
    }
  
    // Prevent multiple leading zeros
    if (newValue.startsWith("0") && newValue[1] !== "." && newValue.length > 1) {
      newValue = newValue.slice(1);
    }
  
    return newValue;
  };

  return (
    <ContainerWrapper className={cx('article')}>
      <Container>
        <Title>Memecoin Pump Calculator</Title>
        <SubTitle>Enter the Price and Amount</SubTitle>
        <InputGroup>
          <Label>Current Price:</Label>
          <Input
            type="number"
            step="0.000001"
            value={price}
            onChange={(e) => setPrice(formatInputValue(e.target.value))}
          />
        </InputGroup>
        <InputGroup>
          <Label>MEME Amount:</Label>
          <Input
            type="number"
            step="1"
            value={amount}
            onChange={(e) => setAmount(formatInputValue(e.target.value))}
          />
        </InputGroup>
        <Result>
          <div>LUNC Required</div>
          <Highlight>{addCommasToNumber(luncRequired.toFixed(3))}</Highlight>
        </Result>
        <Result>
          <div>Price after Buy</div>
          <div>{endPrice.toFixed(6)}</div>
        </Result>
        <Result>
          <div>Your Gain</div>
          <div>{yourGain.toFixed(3)} %</div>
        </Result>
        <Result>
          <div>Pump Gain</div>
          <div>{pumpGain.toFixed(3)} X</div>
        </Result>
      </Container>
    </ContainerWrapper>
  );
};

export default MemeCalculator;
