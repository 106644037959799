import { NetworkInfo, useWallet } from "@terra-money/wallet-provider"
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
} from "react"
import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import Container from "./Container"
import styles from "./SwapPage.module.scss"
import Summary from "../pages/Dashboard/Summary"
import { useQuery } from "react-query"
import useDashboardAPI from "rest/useDashboardAPI"

interface Props {
  title?: ReactNode
  description?: ReactNode
  sm?: boolean
  autoRefreshTicker?: boolean
  onCurrentPriceChange: (currentPrice: number) => void
  onClosePriceChange_30day: (closePrice_30day: number) => void
  onClosePriceChange_15day: (closePrice_15day: number) => void
}

const Page: FC<PropsWithChildren<Props>> = ({
  title,
  description,
  children,
  onCurrentPriceChange, // Callback for current price change
  onClosePriceChange_30day, // Callback for close price change
  onClosePriceChange_15day, // Callback for close price change
  ...props
}) => {
  const { sm } = props

  const lastNetworkRef = useRef<NetworkInfo>()
  const { network } = useWallet()
  const [searchParams, setSearchParams] = useSearchParams()
  const { api } = useDashboardAPI()

  let [luna1Price, setLuna1Price] = useState(0)
  let [luna2Price, setLuna2Price] = useState(0)
  let [btcPrice, setBtcPrice] = useState(0)
  const [autoRefreshTicker, setAutoRefreshTicker] = useState(false)
  // From api to smart contract
  let [currentSupply, setCurrentSupply] = useState(0)
  let [currentPrice, setCurrentPrice] = useState(0)
  let [currentReserve, setCurrentReserve] = useState(0)
  let [taxCollected, setTaxCollected] = useState(0)
  let [minerBurnPercent, setMinerBurnPercent] = useState(0)
  let [minerManager, setMinerManager] = useState(0)
  let [totalMined, setTotalMined] = useState(0)
  let [closePrice_30day, setClosePrice_30day] = useState(0)
  let [closePrice_15day, setClosePrice_15day] = useState(0)

  useLayoutEffect(() => {
    const timerId = setTimeout(() => {
      if (
        network &&
        lastNetworkRef.current &&
        network?.name !== lastNetworkRef.current?.name &&
        window.location.pathname.includes("/swap") &&
        searchParams &&
        setSearchParams
      ) {
        searchParams.set("from", "")
        searchParams.set("to", "")
        setSearchParams(searchParams, { replace: true })
      }
      lastNetworkRef.current = network
    }, 10)

    return () => {
      clearTimeout(timerId)
    }
    // #112: Do not add searchParams, setSearchParams to deps for performance reasons.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network])

  useEffect(() => {
    const timerId = setInterval(() => {
      if (window?.navigator?.onLine && window?.document?.hasFocus()) {
        setAutoRefreshTicker((current) => !current)
      }
    }, 30000)
    return () => {
      clearInterval(timerId)
    }
  }, [])

  useEffect(() => {
    const url1 =
      "https://terra-classic-lcd.publicnode.com//cosmwasm/wasm/v1/contract/terra1wgghrsrr5udn3e4nvuelg4ghtwhpxxtjpp93jj7hhuhqmtqlkm4skw4kqd/smart/eyJnZXRfaW5mbyI6e319"
      fetch(url1)
      .then((response) => response.text())
      .then((text) => {
        console.log(text)
        const tbcData = JSON.parse(text)
        setCurrentPrice(Number(tbcData.data.price.amount) / 1000000)
      })

    const url2 =
      "https://api.coingecko.com/api/v3/simple/price?ids=terra-luna&vs_currencies=usd"
      fetch(url2)
      .then((response) => response.text())
      .then((text) => {
        console.log(text)
        const apiCoinGeckoLuna = JSON.parse(text)
        setLuna1Price(Number(apiCoinGeckoLuna["terra-luna"]["usd"]))
      })

    return
  }, [autoRefreshTicker])

  const { data: chart } = useQuery("baseswap_price", async () => {
    const res = await api.baseswap_price.getChartData()
    setClosePrice_30day(parseFloat(res[179].liquidityUst) / 1000000)
    setClosePrice_15day(parseFloat(res[89].liquidityUst) / 1000000)
  })

  useEffect(() => {
    onCurrentPriceChange(currentPrice)
  }, [currentPrice, onCurrentPriceChange])

  useEffect(() => {
    onClosePriceChange_30day(closePrice_30day)
  }, [currentPrice, onCurrentPriceChange])

  useEffect(() => {
    onClosePriceChange_15day(closePrice_15day)
  }, [currentPrice, onCurrentPriceChange])

  return (
    <article className={styles.article} style={{ textAlign: 'center' }}>
      <Summary
        data={[
          {
            label: "BASE-LUNC ",
            value: currentPrice.toString(),
            isCurrency: false,
            decimals: 6,
          },

        ]}
      />

      <br></br>
      <br></br>

      {sm ? <Container sm>{children}</Container> : children}
    </article>
  )
}

export default Page
