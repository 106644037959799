import { Int, MsgExecuteContract } from "@terra-money/terra.js"
import { useAddress } from "hooks"
import { div, floor, times } from "libs/math"
import { toAmount } from "libs/parse"
import { Type } from "pages/Vest"
import { useEffect, useMemo, useState } from "react"
import { tokenInfos } from "./usePairs"


type Params = {
  from: string
  to: string
  amount: number | string
  type?: Type
  staking_days: number
  description: string
}

const useTBC2 = (params: Params) => {
  const walletAddress = useAddress()
  const {
    from,
    to,
    type,
    amount: _amount,
    staking_days, 
    description,
  } = params

  const amount = Number(_amount)
  const debouncedAmount = useDebounce(amount, 500)
  const [isLoading, setIsLoading] = useState(false)
  const [msgs, setMsgs] = useState<
    (MsgExecuteContract[] | MsgExecuteContract)[]
  >([])
  const [autoRefreshTicker, setAutoRefreshTicker] = useState(false)
  const [simulatedAmounts, setSimulatedAmounts] = useState<number[]>([])

  // From api to smart contract
  let [currentPrice, setCurrentPrice] = useState(0)
  let [luna1Price, setLuna1Price] = useState(0)

  let currentMarketCap = 0
  const tokenInfo = tokenInfos.get(to)

  // Will calculate new values
  let tokensToStake = 0
  let reserveReturned = 0
  let newMarketCap = 0

  const profitableQuery = useMemo(() => {
    let luncUsdPrice = 0
    let luncLbunPrice = 0
    let coinDeposit = 0
    let msg = undefined

    let simulatedAmount = 0
    let rate = 0

    console.log("useMemo triggered: ", staking_days, description);

    setIsLoading(true)
    if (!to || !debouncedAmount) {
      return undefined
    } else if (from === "uluna") {
      const taxPercentage = 0.025
      
      //Amount of LUNC after the seller fee and min transaction fee is deducted
      //debouncedAmount must be a minimum of 1 Million LUNC for Vesting
      const buyAmount = (debouncedAmount >= 1000000) ? Math.floor((debouncedAmount * (1 - taxPercentage)) - 72.5) : 0;

      //Calculates cw20 that remaining balance can buy
      tokensToStake = buyAmount / currentPrice
      //console.log(currentPrice)
      simulatedAmount = Math.floor(tokensToStake * 1000000) //cuz 6 decimals
      simulatedAmount = simulatedAmount < 0 ? 0 : simulatedAmount

      rate = buyAmount / tokensToStake
      rate = rate < 0 ? 0 : rate

      const executeMsg = {
        buy:{
          denom: "uluna", 
          price: (currentPrice * 1000000).toString(), 
          selected_reward_period: {duration_months:6,  apr_percentage:0}
        },
      }
      
      msg = new MsgExecuteContract(
        walletAddress,
        "terra1wgghrsrr5udn3e4nvuelg4ghtwhpxxtjpp93jj7hhuhqmtqlkm4skw4kqd",
        executeMsg,
        { uluna: (debouncedAmount * 1000000).toString() }
      )
    } else {
      return undefined
    }


    luncUsdPrice = luna1Price
    luncLbunPrice = rate

    const tokenRoutes: string[] = []
    
    setIsLoading(false)
    return {
      msg,
      luncUsdPrice,
      luncLbunPrice: luncLbunPrice.toString(),
      simulatedAmount,
      tokenRoutes,
      price: rate.toString(),
      //currentSupply,
      //currentReserve,
      //currentPrice
    }
  }, [to, debouncedAmount, msgs, simulatedAmounts, staking_days, description]) //end of useMemo


  useEffect(() => {
    const url1 =
      "https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1wgghrsrr5udn3e4nvuelg4ghtwhpxxtjpp93jj7hhuhqmtqlkm4skw4kqd/smart/eyJnZXRfaW5mbyI6e319"
    fetch(url1)
      .then((response) => response.text())
      .then((text) => {
        console.log(text)
        const tbcData = JSON.parse(text)
        setCurrentPrice(Number(tbcData.data.price.amount) / 1000000)
      })

    const url2 =
      "https://api.coingecko.com/api/v3/simple/price?ids=terra-luna&vs_currencies=usd"
    fetch(url2)
      .then((response) => response.text())
      .then((text) => {
        console.log(text)
        const apiCoinGeckoLuna = JSON.parse(text)
        setLuna1Price(Number(apiCoinGeckoLuna["terra-luna"]["usd"]))
      })
    return
  }, [debouncedAmount, from, to, type, isLoading])

  useEffect(() => {
    const timerId = setInterval(() => {
      setAutoRefreshTicker((current) => !current)
    }, 30000)
    return () => {
      clearInterval(timerId)
    }
  }, [debouncedAmount, from])

  const result = useMemo(() => {
    if (!from || !to || !type || !debouncedAmount) {
      return { profitableQuery: undefined, isLoading: false }
    }
    return {
      isLoading,
      profitableQuery,
    }
  }, [debouncedAmount, from, isLoading, profitableQuery, to, type])

  return result
}

// Generic deBounce Hook ( https://usehooks.com/useDebounce/ )
function useDebounce(value: number, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export default useTBC2
