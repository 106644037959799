import React, { useState, useEffect } from "react"
import "table.css"
import useDashboardAPI from "rest/useDashboardAPI"
import { floor } from "libs/math"

function VirtualMining() {
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50) // Number of items to display per page

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const timestamp = Date.now() // Get the current timestamp and use for cache-Busting
      const url = `https://raw.githubusercontent.com/lbunproject/BASEswap-api-price/main/public/winner_database.json?t=${timestamp}`
      const response = await fetch(url)
      const data = await response.json()
      setTableData(data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  // Function to generate table rows for the current page
  const renderTableRows = () => {
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem)

    return currentItems.map((row, index) => {
      //calculate how many days of life the nft has
  
      return (
        <tr key={index}>
          <td>{row["Virtual Block"]}</td>
          <td>{row.Date} {row.Time}</td>
          <td>{row.equipment}</td>
          <td>{row.boost}</td>
          <td>{row.location}</td>
          <td>{row.skill}</td>
          <td>{row["Reward (BASE)"]}</td>
          <td>{row.nftLife}</td>
          <td>{`${row.owner.slice(0, 6)}...${row.owner.slice(-6)}`}</td>
      </tr>
      )
    })
  }

function addCommasToNumber(number) {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // Function to handle items per page change
  const handleItemsPerPageChange = (e) => {
    const value = parseInt(e.target.value)
    setItemsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing items per page
  }

  // Calculate the total number of pages
  const totalPages = Math.ceil(tableData.length / itemsPerPage)

  // Generate page number buttons
  const renderPageNumbers = () => {
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      )
    }
    return pageNumbers
  }

  // Generate items per page options
  const renderItemsPerPageOptions = () => {
    const options = [25, 50, 100]
    return options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))
  }

  return (
    <div className="table-container">
      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr>
              <th>VBlock</th>
              <th>Date/Time</th>
              <th>Equipment</th>
              <th>Boost</th>
              <th>Location</th>
              <th>Skill</th>
              <th>BASE Mined</th>
              <th>Life (days)</th>
              <th>Owner</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
      <div className="pagination">
        <div className="items-per-page">
          <label>Items per page:</label>
          <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
            {renderItemsPerPageOptions()}
          </select>
        </div>
        <div className="page-numbers">{renderPageNumbers()}</div>
      </div>
    </div>
  )
}

export default VirtualMining;
